import React from "react"
import SectionContainer from "../components/SectionContainer"
import { Typography, useTheme, Box } from "@material-ui/core"
import { CTA, CTA_NAMES } from "../components/CTAs"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  const theme = useTheme()
  return (
    <Layout>
      <SEO title="404: Not found" />
      <SectionContainer>
        <Box mb={3}>
          <Typography variant="h1">This page does not exist</Typography>
        </Box>
        <Typography variant="body1">
          You hit a route that doesn't exist.
        </Typography>
        <Box mt={4}>
          <CTA name={CTA_NAMES.goHome} />
        </Box>
      </SectionContainer>
    </Layout>
  )
}

export default NotFoundPage
